import type { Session } from "next-auth"
import Footer from "../footer";
import type { AppProps } from 'next/app'
import NavBar from "../navbar";
import { ReactNode } from "react";


const Layout = ({ children }: { children: ReactNode }) => {
    return (
        <div>
            <NavBar/>
            <div>
                {children}
            </div>
            <Footer />
        </div>);
}

export default Layout;